















import Vue from 'vue'
import { useGtm } from '@gtm-support/vue2-gtm'
import Loading from '@/components/common/container/Loading.vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import ErrorDisplay from '@/components/common/container/ErrorDisplay.vue'
import NotificationSnackBar from '@/components/common/container/NotificationSnackBar.vue'
import { StoreError } from '@/types/error'

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    Loading,
    ErrorMessage,
    ErrorDisplay,
    NotificationSnackBar,
  },
  computed: {
    error(): StoreError {
      return this.$accessor.error.getError
    },
    showErrorMessage(): boolean {
      return this.error.statusCode === 404 || (this.error.statusCode === 403 && this.error.errorCode === 40302)
    },
  },
  watch: {
    $route(newRoute) {
      // always hide notification on route change
      // Unless keepNotification query param is true
      if (newRoute.query.keepNotification !== 'true') {
        this.$accessor.notification.clearNotification()
      }
    },
  },
  mounted() {
    this.initGoogleTagManager()
  },
  methods: {
    initGoogleTagManager() {
      /**
       *  When page is loaded first time, user context is not available
       *  So, router plugin will not push any events
       *  Explicitly handle first time page load google tag push
       */
      const loginUser = this.$accessor.user.loginUser

      window.dataLayer = window.dataLayer || []
      // set google tag params and push custom event
      // 先にユーザープロパティの設定
      window.dataLayer.push({
        user_id: loginUser?.id.toString() ?? '',
      })
      window.dataLayer.push({
        user_properties: {
          genkone_user_id: loginUser?.id.toString() ?? '',
          organization_id: loginUser?.organization?.id.toString() ?? '',
          role_id: loginUser?.role?.id.toString() ?? '',
          division_id: loginUser?.division?.id.toString() ?? '',
        },
      })
      const gtm = useGtm()
      gtm?.trackEvent({
        event: 'nuxtRoute',
      })
    },
  },
})
