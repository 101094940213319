




























import Vue from 'vue'

export default Vue.extend({
  computed: {
    isShow: {
      get(): boolean {
        return this.$accessor.notification.notificationSnackBar.isShow
      },
      set() {
        // set is only done via v-model to hide
        this.$accessor.notification.clearNotification()
      },
    },
    notification() {
      return this.$accessor.notification.notificationSnackBar
    },
  },
  methods: {
    onClickAction() {
      this.$accessor.notification.clearNotification()
      this.notification.action?.onClick()
    },
    handleActionClick() {
      this.notification.action?.onClick()
      this.isShow = false // Hide the notification
    },
  },
})
