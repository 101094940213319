import { getIconPriority } from '@/utils/issue'
import { PriorityApi, Priority } from '@/api/api'

export interface PriorityEntity {
  id: number
  name: string
  iconName?: string
  iconColor?: string
  backgroundColor?: string
  fontColor?: string
  group: string
}

export class PriorityRepository {
  private readonly priorityApi: PriorityApi
  constructor(priorityApi: PriorityApi) {
    this.priorityApi = priorityApi
  }

  async getPriority(): Promise<PriorityEntity[]> {
    const { data } = await this.priorityApi.getPrioritiesListOfIssue()
    if (data?.data && data.ok) {
      return data.data.map((v: Priority) => {
        const { icon, color, backgroundColor, fontColor } = getIconPriority(v)

        return {
          id: v.id,
          name: v.name,
          iconName: icon,
          iconColor: color,
          backgroundColor,
          fontColor,
          group: v.group,
        }
      })
    } else {
      return []
    }
  }
}
