import { UserEntity } from '@/repository/UserRepository'

export const SOLUTIONS = Object.freeze({
  PHUB: '2',
})

export const USER_ROLES = Object.freeze({
  ADMIN: 1,
  GENERAL: 2,
  RESTRICTED: 3,
})

export const LIMITS = Object.freeze({
  ISSUE_ATTACHED_FILE: 30,
  FACILITY_ATTACHED_FILE: 30,
  COMMENT_ATTACHED_FILE: 10,
  ISSUE_FACILITY_FILE_SIZE: Math.pow(1024, 3),
})

// datastore の定義のコピー
// https://github.com/sensyn-robotics/fc-datastore/blob/8079e2fd209c968928679260d76697d55069a7ee/api/app/Models/Resource/FileDownloadTask.php#L11-L15
// enum を利用しない理由
// https://qiita.com/saba_can00/items/696baa5337eb10c37342
// 下記のコードの挙動について
// https://qiita.com/saba_can00/items/bdefb28a1873658cf5d9
export const FILE_STATUS = Object.freeze({
  INITIALIZE: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  CANCELED: 3,
  ERROR: 9,
})

export type FileStatus = typeof FILE_STATUS[keyof typeof FILE_STATUS]

export const DUE_DATE_ALERT = Object.freeze({
  DEFAULT: 0,
  DUE_SOON: 1,
  OVER_DUE: 2,
  EMPTY: null,
})

export const DUE_DATE_DISPLAY_DICT: ReadonlyMap<number, object> = new Map([
  [
    DUE_DATE_ALERT.DEFAULT,
    {
      class: 'default',
      icon: '',
    },
  ],
  [
    DUE_DATE_ALERT.DUE_SOON,
    {
      class: 'due-soon',
      icon: 'mdi-clock-alert-outline',
    },
  ],
  [
    DUE_DATE_ALERT.OVER_DUE,
    {
      class: 'over-due',
      icon: 'mdi-clock-remove-outline',
    },
  ],
])

export const FACILITY_PIN_KIND = Object.freeze({
  INFO: 'info',
  CHECK: 'chck',
  WARNING: 'warn',
})

export const ALLOWED_ISSUE_FORM_REQUEST_ORIGIN = ['issue', 'property'] as const
export const ALLOWED_ISSUE_FORM_INITIAL_FOCUS = ['floor', 'property', 'dueDate'] as const
export const ALLOWED_FACILITY_FORM_REQUEST_ORIGIN = ['facility', 'property'] as const
export const ALLOWED_FACILITY_FORM_INITIAL_FOCUS = ['floor', 'property'] as const

export const ALL_PANORAMA_STEPS = ['upload', 'naming', 'adjust-position'] as const

export const NO_ASSIGNEE_ITEM: UserEntity = {
  id: 0,
  name: '未設定',
  nameKana: '',
  iconUrl: '/icons/account_question_outline.svg',
  division: null,
  role: null,
  organization: null,
  isDeleted: false,
  isSuperUser: false,
}

export const FILE_NAME_INVALID_REGEX = /[\\/:*?"<>|#%{}|\\^~[\]`]/
