import { NotificationSettingsUpdateRequest, NotificationSettingsApi } from '@/api/api'
import { toCamelCase, toSnakeCase } from '@/utils/common'

export interface NotificationSettingsType {
  pushNotification: boolean
  mailNotification: boolean
}

export interface NotificationSettingsEvent {
  whenAssignedProperty: boolean
}

export interface NotificationSettingsTimeTriggerTarget {
  bookmarkedIssue: boolean
  ownedIssue: boolean
  assignedIssue: boolean
}

export interface NotificationSettingsEntity {
  type: NotificationSettingsType
  event: NotificationSettingsEvent
  timeTriggerTarget: NotificationSettingsTimeTriggerTarget
}

export interface UpdateNotificationSettingsType {
  pushNotification?: boolean
  mailNotification?: boolean
}

export interface UpdateNotificationSettingsEvent {
  whenAssignedProperty?: boolean
}

export interface UpdateNotificationSettingsTimeTriggerTarget {
  bookmarkedIssue?: boolean
  ownedIssue?: boolean
  assignedIssue?: boolean
}

export interface updateNotificationEntity {
  type?: UpdateNotificationSettingsType
  event?: UpdateNotificationSettingsEvent
  timeTriggerTarget?: UpdateNotificationSettingsTimeTriggerTarget
}

export interface NotificationSnackBarEntity {
  isShow: boolean
  isSuccess?: boolean
  message: string
  timeout: number
  action?: {
    text: string
    onClick: Function
  }
}

export class NotificationSettingRepository {
  private readonly notificationSettingsApi: NotificationSettingsApi

  constructor(notificationSettingsApi: NotificationSettingsApi) {
    this.notificationSettingsApi = notificationSettingsApi
  }

  async getNotificationSettings(): Promise<NotificationSettingsEntity> {
    const { data } = await this.notificationSettingsApi.getNotificationSettings()
    if (data?.data && data.ok) {
      const notificationSettings: NotificationSettingsEntity = toCamelCase(data?.data)
      return notificationSettings
    } else {
      throw new Error('API Error')
    }
  }

  async updateNotificationSettings(
    notificationSettings: NotificationSettingsEntity
  ): Promise<NotificationSettingsEntity> {
    const updateNotifications: NotificationSettingsUpdateRequest = toSnakeCase(notificationSettings)
    const { data } = await this.notificationSettingsApi.patchNotificationSettings(updateNotifications)
    if (data?.data && data.ok) {
      const notificationSettings: NotificationSettingsEntity = toCamelCase(data?.data)
      return notificationSettings
    } else {
      throw new Error('API Error')
    }
  }
}
