import { VrCoordinates } from '@/api/api'
import { AttachmentFileEntity, AttachmentOperationsEntity } from '@/repository/FileRepository'
import { CreateIssueEntity, IssueDetailEntity, UpdateIssueEntity } from '@/repository/IssueRepository'
import { PanoramaEntity } from '@/repository/ShotPointRepository'
import { Coordinate } from '@/types/property'

// dayjs定義
const dayjs = require('dayjs')

export default class SimpleIssueEntity {
  id: number | undefined
  issueCode: string
  status: number
  priority: number
  title: string
  description: string
  // note: assignee currently can select multiple from the UI selection
  assignees: number[]
  notificationTargets: number[]
  dueDate: string | null
  propertyId: number
  floorId: number | null
  shotpointId: number | null
  panorama: PanoramaEntity | null
  pvrObjectId: number | null
  pvrCoordinate: VrCoordinates | null
  files: (File | AttachmentFileEntity)[]
  attachmentOperations?: AttachmentOperationsEntity
  jstDueDate: string | null
  floorPlanId: number | null
  floorPlanObjectId: number | null
  floorPlanCoordinate: Coordinate | null

  constructor() {
    this.id = undefined
    this.issueCode = ''
    this.status = 0
    this.priority = 0
    this.title = ''
    this.description = ''
    this.assignees = []
    this.notificationTargets = []
    this.dueDate = null
    this.propertyId = 0
    this.floorId = null
    this.shotpointId = null
    this.panorama = null
    this.pvrObjectId = null
    this.pvrCoordinate = null
    this.files = []
    this.attachmentOperations = undefined
    this.jstDueDate = null
    this.floorPlanId = null
    this.floorPlanObjectId = null
    this.floorPlanCoordinate = null
  }

  /**
   * 差分から更新リクエストを組み立てる。
   * 差分がなければnullを返し、リクエストの送信が不要であることを伝える。
   */
  buildUpdateRequestFromDiff(newOne: SimpleIssueEntity): UpdateIssueEntity | null {
    const request: UpdateIssueEntity = {}

    if (newOne.status !== this.status) {
      request.status = newOne.status
    }
    if (this.priority !== newOne.priority) {
      request.priority = newOne.priority
    }
    if (this.title !== newOne.title) {
      request.title = newOne.title
    }
    if (this.description !== newOne.description) {
      request.description = newOne.description
    }
    if (
      newOne.assignees.length !== this.assignees.length ||
      !newOne.assignees.every((assignee, index) => assignee === this.assignees[index])
    ) {
      request.assignees = newOne.assignees
    }
    if (
      newOne.notificationTargets.length !== this.notificationTargets.length ||
      !newOne.notificationTargets.every(
        (notificationTarget, index) => notificationTarget === this.notificationTargets[index]
      )
    ) {
      request.notificationTargets = newOne.notificationTargets
    }
    if (this.dueDate !== newOne.dueDate) {
      request.dueDate = newOne.dueDate
    }
    if (this.shotpointId) {
      // when shotpoint is changed, or removed, do unlink
      // there is no such thing as patching for now
      // API doesnt cater shotpoint change when patching
      if (newOne.shotpointId !== this.shotpointId) {
        request.linkedPanoramaOperation = {
          unlink: true,
        }
        if (newOne.shotpointId && newOne.pvrCoordinate) {
          request.linkedPanoramaOperation.link = {
            shotPointId: newOne.shotpointId,
            vrCoordinates: newOne.pvrCoordinate,
          }
        }
      }
      // when coordinate changes, patch the coordinate
      // not minding the objectID since currently no way it will change
      else if (
        newOne.pvrObjectId &&
        this.pvrCoordinate &&
        newOne.pvrCoordinate &&
        (this.pvrCoordinate.yaw !== newOne.pvrCoordinate.yaw ||
          this.pvrCoordinate.pitch !== newOne.pvrCoordinate?.pitch)
      ) {
        request.linkedPanoramaOperation = {
          patch: {
            vrCoordinates: newOne.pvrCoordinate,
          },
        }
      }
    } else if (newOne.shotpointId && newOne.pvrCoordinate) {
      request.linkedPanoramaOperation = {
        link: {
          shotPointId: newOne.shotpointId,
          vrCoordinates: newOne.pvrCoordinate,
        },
      }
    }
    if (this.floorPlanId) {
      // when floorPlanId is removed, do unlink
      // there is no such thing as patching for now
      // API doesnt cater floorPlan change when patching
      if (newOne.floorPlanId !== this.floorPlanId) {
        request.linkedFloorPlanOperation = {
          unlink: true,
        }
        if (newOne.floorPlanId && newOne.floorPlanCoordinate) {
          request.linkedFloorPlanOperation.link = {
            floorPlanId: newOne.floorPlanId,
            coordinate: newOne.floorPlanCoordinate,
          }
        }
        // when coordinate changes, patch the coordinate
        // not minding the floorPlanObjectId since currently no way it will change
      } else if (
        newOne.floorPlanId &&
        this.floorPlanCoordinate &&
        newOne.floorPlanCoordinate &&
        (this.floorPlanCoordinate.latitude !== newOne.floorPlanCoordinate.latitude ||
          this.floorPlanCoordinate.longitude !== newOne.floorPlanCoordinate.longitude)
      ) {
        request.linkedFloorPlanOperation = {
          patch: {
            coordinate: newOne.floorPlanCoordinate,
          },
        }
      }
    } else if (newOne.floorPlanId && newOne.floorPlanCoordinate) {
      request.linkedFloorPlanOperation = {
        link: {
          floorPlanId: newOne.floorPlanId,
          coordinate: newOne.floorPlanCoordinate,
        },
      }
    }
    // attachmentOperations.deleteFilesをここで追加する
    // note: rather than attachmentOperation, deleteFiles Array should be fine
    // or cater attachmentOperation create and delete here as well, not delete only
    if (newOne.attachmentOperations) {
      request.attachmentOperations = newOne.attachmentOperations
    }
    if (this.floorId !== newOne.floorId) {
      request.propertyId = newOne.propertyId
      request.floorId = newOne.floorId || null
    }

    if (Object.keys(request).length === 0 && this.files.length === newOne.files.length) {
      return null
    }

    request.issueId = this.id
    return request
  }

  toCreateRequest(): CreateIssueEntity {
    if (this.jstDueDate) {
      this.dueDate = SimpleIssueEntity.jstToUtcDateTime(this.jstDueDate)
    }
    // attachmentOperations.createFilesは、外部のコンテナで追加する
    const req: CreateIssueEntity = {
      issueCode: this.issueCode, // 本来リクエストにはいらないはずだが、影響範囲が大きいので残している。(APIは無視する)
      title: this.title,
      description: this.description,
      assignees: this.assignees,
      notificationTargets: this.notificationTargets,
      priority: this.priority,
      dueDate: this.dueDate,
      propertyId: this.propertyId,
      floorId: this.floorId || null,
    }
    if (this.shotpointId && this.pvrCoordinate) {
      req.linkedPanoramaOperation = {
        link: {
          shotPointId: this.shotpointId,
          vrCoordinates: this.pvrCoordinate,
        },
      }
    }
    if (this.floorPlanId && this.floorPlanCoordinate) {
      req.linkedFloorPlanOperation = {
        link: {
          floorPlanId: this.floorPlanId,
          coordinate: this.floorPlanCoordinate,
        },
      }
    }
    return req
  }

  static fromIssueDetailEntity(issue: IssueDetailEntity): SimpleIssueEntity {
    const self = new SimpleIssueEntity()
    self.id = issue.id
    self.status = issue.status
    self.priority = issue.priority ?? 0
    self.title = issue.title
    self.description = issue.description
    self.assignees = issue.assignees
    self.notificationTargets = issue.notificationTargets
    self.dueDate = issue.dueDate
    if (self.dueDate) {
      self.jstDueDate = SimpleIssueEntity.utcToJstDate(self.dueDate)
    }
    self.propertyId = issue.propertyId
    self.floorId = issue.floorId || 0
    self.shotpointId = null
    self.panorama = null
    self.pvrObjectId = null
    self.pvrCoordinate = null
    self.floorPlanId = null
    self.floorPlanObjectId = null
    self.floorPlanCoordinate = null
    self.files = []
    if (issue.attachmentFiles) {
      self.files = issue.attachmentFiles
    }
    if (issue.linkedPanorama) {
      self.shotpointId = issue.linkedPanorama.shotPointId
      self.panorama = issue.linkedPanorama.latestPanorama
      self.pvrObjectId = issue.linkedPanorama.pvrObjectId
      self.pvrCoordinate = {
        yaw: issue.linkedPanorama.vrCoordinates.yaw,
        pitch: issue.linkedPanorama.vrCoordinates.pitch,
      }
    }
    if (issue.linkedFloorPlan) {
      self.floorPlanId = issue.linkedFloorPlan.floorPlanId
      self.floorPlanObjectId = issue.linkedFloorPlan.floorPlanObjectId
      self.floorPlanCoordinate = {
        latitude: issue.linkedFloorPlan.coordinate.latitude,
        longitude: issue.linkedFloorPlan.coordinate.longitude,
      }
    }
    self.attachmentOperations = undefined

    return self
  }

  // JSTからUTCの日時に変換して日付を返す
  // param jstDate:yyyy-MM-dd形式
  // return:YYYY-MM-DD HH:mm:ssZ形式
  static jstToUtcDateTime(jstDate: string): string {
    const jstDateTime = jstDate + ' 00:00:00+09:00'
    const datestr = dayjs.utc(jstDateTime).format('YYYY-MM-DD HH:mm:ss') + 'Z'
    return datestr
  }

  // UTCからJSTの日付に変換して日付を返す
  // param utcDateTime:yyyy-MM-dd HH:mm:ssZ形式
  // return:YYYY-MM-DD形式
  static utcToJstDate(utcDateTime: string): string {
    const jstDateTime = dayjs.utc(utcDateTime).tz()
    return jstDateTime.format('YYYY-MM-DD')
  }
}
